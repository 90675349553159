$(document).ready(function() {
  if ($('#fd').length > 0) {
    initDates('search');
  }

  if ($('#btn-search-rent').length > 0) {
    $('#btn-search-rent').on('click', e => {
      const ageCheck = $('#age-check').is(':checked')
      if (!ageCheck) {
        alert('Age regulations apply. Your reservation may be rejected if you do not meet the age requirements.');
        return;
      }
    });
  }

  const modifySearchBtn = document.getElementById('modify-search-btn');
  if (modifySearchBtn) {
    modifySearchBtn.addEventListener('click', () => {
      const searchWidget = document.querySelector('.search-section');

      searchWidget.classList.toggle('expand');
    });
  }

  // When user clicks on different-dropoff checkbox then enable select element within container #rl-container
  if ($('#different-dropoff').length > 0) {
    $('#different-dropoff').on('click', function() {
      if ($(this).is(':checked')) {
        $('#rl-container').removeClass('is-hidden');
      } else {
        $('#rl-container').addClass('is-hidden');
        // Change rl location to point to pl location value
        $('#rl').val($('#pl').val());
      }
    });

    // If different-dropoff is checked then enable select element within container #rl-container
    if ($('#different-dropoff').is(':checked')) {
      $('#rl-container').removeClass('is-hidden');
    }
  }

  if ($('#pl').length > 0) {
    $('#pl').on('change', function() {
      const isDifferentDropoff = $('#different-dropoff').is(':checked');
      if (!isDifferentDropoff) {
        $('#rl').val($(this).val());
      }
    });
  }
});
